const firebaseConfig = {
  apiKey: 'AIzaSyD2APzmuSdi3HHB3tYB-injmwRwQT55i50',
  authDomain: 'involve-a7a2d.firebaseapp.com',
  databaseURL: 'https://involve-a7a2d.firebaseio.com',
  projectId: 'involve-a7a2d',
  storageBucket: 'involve-a7a2d.appspot.com',
  messagingSenderId: '703869582234',
}

export default firebaseConfig

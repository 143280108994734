import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import classNames from 'classnames'
import firebase from 'firebase/app'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import styles from './App.module.scss'

// Components
import FullScreenLoader from 'components/FullScreenLoader'
import Menu from 'components/Menu'
import Header from 'components/Header'

// Thunk actions
import loginAction from 'functions/thunk/loginAction'
import dispatchError from 'functions/thunk/dispatchError'

// Views
const AsyncLogin = Loadable({
  loader: () => import('views/Login'),
  loading: FullScreenLoader,
})

const AsyncOrgNotAssigned = Loadable({
  loader: () => import('views/OrgNotAssigned'),
  loading: FullScreenLoader,
})

const AsyncActiveCampaigns = Loadable({
  loader: () => import('views/ActiveCampaigns'),
  loading: FullScreenLoader,
})

const AsyncWaitingCampaigns = Loadable({
  loader: () => import('views/WaitingCampaigns'),
  loading: FullScreenLoader,
})

const AsyncFinishedCampaigns = Loadable({
  loader: () => import('views/FinishedCampaigns'),
  loading: FullScreenLoader,
})

const AsyncVideosView = Loadable({
  loader: () => import('views/Videos'),
  loading: FullScreenLoader,
})

const AsyncSearchView = Loadable({
  loader: () => import('views/SearchCampaigns'),
  loading: FullScreenLoader,
})

const AsyncArchivedCampaigns = Loadable({
  loader: () => import('views/ArchivedCampaigns'),
  loading: FullScreenLoader,
})

const AsyncTrashCampaigns = Loadable({
  loader: () => import('views/TrashCampaigns'),
  loading: FullScreenLoader,
})

const AsyncSingleCampaign = Loadable({
  loader: () => import('views/SingleCampaign'),
  loading: FullScreenLoader,
})

const AsyncSingleArchive = Loadable({
  loader: () => import('views/SingleArchive'),
  loading: FullScreenLoader,
})

const AsyncSingleTrash = Loadable({
  loader: () => import('views/SingleTrash'),
  loading: FullScreenLoader,
})

const AsyncSettings = Loadable({
  loader: () => import('views/Settings'),
  loading: FullScreenLoader,
})

const AsyncNotFound = Loadable({
  loader: () => import('views/NotFound'),
  loading: FullScreenLoader,
})

const AsyncApplicationError = Loadable({
  loader: () => import('views/ApplicationError'),
  loading: FullScreenLoader,
})

// App
class App extends Component {
  authStateChangedSubscription = null

  componentDidMount() {
    this.authStateChangedSubscription = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        this.props.dispatch(loginAction()).catch(err => {
          this.props.dispatch(dispatchError(err))
          this.props.dispatch({
            type: 'app/CHANGE_LOADING_STATUS',
            payload: false,
          })
        })
      } else {
        this.props.dispatch({
          type: 'app/CHANGE_LOADING_STATUS',
          payload: false,
        })
      }
    })
  }

  componentDidUpdate = prevProps => {
    if (prevProps.hasOrg !== this.props.hasOrg) {
      this.loadAfter()
    }
  }

  componentWillUnmount() {
    // Clear subscription
    this.authStateChangedSubscription()
  }

  render() {
    const { loggedIn, loading, location, appError } = this.props

    if (appError) {
      return <AsyncApplicationError error={appError} />
    }

    if (loading) {
      return <FullScreenLoader />
    }

    if (!loggedIn) {
      return <AsyncLogin />
    }

    return (
      <div className={classNames(styles.main)}>
        <Menu />
        <Header />
        <div className={styles.view}>
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames='fade' timeout={500}>
              <Switch location={location}>
                <Route exact path='/' component={AsyncActiveCampaigns} />
                <Route exact path='/waiting' component={AsyncWaitingCampaigns} />
                <Route exact path='/finished' component={AsyncFinishedCampaigns} />
                <Route exact path='/settings' component={AsyncSettings} />
                <Route exact path='/videos' component={AsyncVideosView} />
                <Route exact path='/search' component={AsyncSearchView} />
                <Route exact path='/archive' component={AsyncArchivedCampaigns} />
                <Route exact path='/trash' component={AsyncTrashCampaigns} />
                <Route path='/campaign/:id' component={AsyncSingleCampaign} />
                <Route path='/archive/:id' component={AsyncSingleArchive} />
                {/* <Route path='/trash/:id' component={AsyncSingleTrash} /> */}
                <Route component={AsyncNotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, router }) => ({
  loggedIn: app.loggedIn,
  loading: app.loading,
  appError: app.error,
  location: router.location,
})

export default connect(mapStateToProps)(App)

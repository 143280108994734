import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import styles from './index.module.scss'

const BasicSelect = ({
  options,
  value,
  onChange,
  strings,
  showKey = false,
  showNoneSeclected = false,
}) => {
  const newValue = e => {
    e.preventDefault()
    onChange(e.target.value)
  }

  return (
    <div className={styles.container}>
      <select value={value} onChange={newValue} className={styles.select}>
        {showNoneSeclected && (
          <option value='' key='none' className={styles.option}>
            {strings.none_selected}
          </option>
        )}
        {typeof options === 'object' &&
          Object.keys(options).map(key => (
            <option value={key} key={key} className={styles.option}>
              {options[key]}
              {showKey && ' (' + key + ')'}
            </option>
          ))}
        {typeof options === 'array' &&
          options.map(option => {
            return (
              <option value={option.value} key={option.value} className={styles.option}>
                {option.name}
                {showKey && ' (' + option.value + ')'}
              </option>
            )
          })}
      </select>
    </div>
  )
}

BasicSelect.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  showKey: PropTypes.bool,
  showNoneSeclected: PropTypes.bool,
}

export default connect(({ app }) => ({ strings: app.strings }))(BasicSelect)

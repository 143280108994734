const dispatchError = error => {
  return dispatch => {
    dispatch({
      type: 'app/SET_ERROR_STATUS',
      payload: error,
    })
  }
}

export default dispatchError

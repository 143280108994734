import firebase from 'firebase/app'

// Functions
import getUser from 'functions/functions/getUser'

const loginAction = () => {
  return async dispatch => {
    const setupUserSubscription = async userId => {
      return firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .onSnapshot(doc => dispatch({ type: 'user/UPDATE_USER', payload: doc.data() }))
    }

    const setupOrgSubscription = async orgId => {
      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .onSnapshot(doc => dispatch({ type: 'org/UPDATE_ORG', payload: doc.data() }))
    }

    const setupCmpsSubscription = async orgId => {
      dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: true })

      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('cmps')
        .where('live', '==', true)
        .onSnapshot(snap => {
          let cmps = {}
          snap.forEach(doc => {
            let cmp = doc.data()
            cmps[cmp.id] = cmp
          })

          dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: false })
          dispatch({ type: 'campaigns/UPDATE_LIVE_CAMPAIGNS', payload: cmps })
        })
    }

    const setupWaitingCmpsSubscription = async orgId => {
      dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: true })

      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('cmps')
        .where('waitingForApproval', '==', true)
        .onSnapshot(snap => {
          let cmps = {}
          //let i = 0
          snap.forEach(doc => {
            let cmp = doc.data()
            cmps[cmp.id] = cmp
          })

          dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: false })
          dispatch({ type: 'campaigns/UPDATE_WAITING_CAMPAIGNS', payload: cmps })
        })
    }

    const setupFinishedCmpsSubscription = async orgId => {
      dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: true })

      let aYearAgo = new Date((new Date()).valueOf() - 1000*60*60*24*365);

      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('cmps')
        .where('completed', '==', true)
        .where('created', '>=', aYearAgo.getTime())
        .orderBy('created', 'desc')
        .limit(50)
        .onSnapshot(snap => {
          let cmps = {}
          //let i = 0
          snap.forEach(doc => {
            let cmp = doc.data()
            cmps[cmp.id] = cmp
          })

          dispatch({ type: 'campaigns/LOADING_CAMPAIGNS', payload: false })
          dispatch({ type: 'campaigns/UPDATE_CAMPAIGNS', payload: cmps })
        })
    }

    const setupArchivesSubscription = async orgId => {
      dispatch({ type: 'campaigns/LOADING_ARCHIVES', payload: true })

      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('archive')
        .orderBy('created', 'desc')
        .limit(30)
        .onSnapshot(snap => {
          let archives = {}
          snap.forEach(doc => {

            let archive = doc.data()
            archives[archive.id] = archive
          })
          dispatch({ type: 'campaigns/LOADING_ARCHIVES', payload: false })
          dispatch({ type: 'campaigns/SET_ARCHIVES', payload: archives })
        })
    }

    const setupTrashSubscription = async orgId => {
      dispatch({ type: 'campaigns/LOADING_TRASH', payload: true })
      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('trash')
        .orderBy('created', 'desc')
        .limit(30)
        .onSnapshot(snap => {
          let trashes = {}
          snap.forEach(doc => {

            let trash = doc.data()
            trashes[trash.id] = trash
          })
          dispatch({ type: 'campaigns/LOADING_TRASH', payload: false })
          dispatch({ type: 'campaigns/SET_TRASH', payload: trashes })
        })
    }

    const setupVideosSubscription = async orgId => {
      return firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('videos')
        .onSnapshot(snap => {
          let videos = {}
          snap.forEach(doc => {
            let video = doc.data()
            videos[video.id] = video
          })
          dispatch({ type: 'campaigns/SET_VIDEOS', payload: videos })
        })
    }

    dispatch({
      type: 'app/CHANGE_LOADING_STATUS',
      payload: true,
    })

    const uid = firebase.auth().currentUser.uid
    const localStorage = window.localStorage

    let orgId = null

    let user = await getUser(uid).catch(err => {
      throw 'failed to load user'
    })

    if (user) {
      let { orgs } = user

      if (orgs && orgs.length > 0) {
        let storageOrg = localStorage.getItem('currentOrg')

        if (orgs.includes(storageOrg)) {
          orgId = storageOrg
        } else {
          orgId = orgs[0]
          localStorage.setItem('currentOrg', orgId)
        }

        return Promise.all([
          setupUserSubscription(user.id),
          setupOrgSubscription(orgId),
          setupCmpsSubscription(orgId),
          setupWaitingCmpsSubscription(orgId),
          setupFinishedCmpsSubscription(orgId),
          setupVideosSubscription(orgId),
          setupArchivesSubscription(orgId),
          setupTrashSubscription(orgId)
        ])
          .then(() => {
            //setTimeout(() => {
              dispatch({
                type: 'app/LOGIN',
              })
              dispatch({
                type: 'app/CHANGE_LOADING_STATUS',
                payload: false,
              })
            //}, 10000)
          })
          .catch(err => {
            console.log(err)
            throw 'failed to load assets'
          })
      } else {
        throw 'user not assigned to any orgs'
      }
    } else {
      throw 'failed to load user'
    }
  }
}

export default loginAction

import React from 'react'

import loader from 'assets/svg/loader.svg'

import styles from './index.module.scss'

const FullScreenLoader = () => (
  <div className={styles.main}>
    <img src={loader} className={styles.loader} alt='Loader' />
  </div>
)

export default FullScreenLoader

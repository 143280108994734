import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

// import * as serviceWorker from './serviceWorker'

import store, { history } from 'redux/store'
import App from 'App/App'

import 'sanitize.css/sanitize.css'
import 'styles/global.scss'
import 'styles/transitions.scss'

// Init Firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/database'
import firebaseConfig from 'config/firebaseConfig'
firebase.initializeApp(firebaseConfig)
firebase.app().functions('europe-west1')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()

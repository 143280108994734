import en from 'config/lang/en'
import nb from 'config/lang/nb'

export const CHANGE_LOADING_STATUS = 'app/CHANGE_LOADING_STATUS'
export const SET_ERROR_STATUS = 'app/SET_ERROR_STATUS'
export const SET_LANGUAGE = 'app/SET_LANGUAGE'
export const RESET_STATE = 'app/RESET_STATE'
export const LOGIN = 'app/LOGIN'

const strings = {
  en,
  nb,
}

const initialState = {
  loading: true,
  loggedIn: false,
  error: null,
  lang: 'nb',
  strings: {
    ...strings['en'],
    ...strings['nb'],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialState,
      }
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
      }
    case CHANGE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_ERROR_STATUS:
      return {
        ...state,
        error: action.payload,
      }

    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
        strings: { ...strings['en'], ...strings[action.payload] },
      }

    default:
      return state
  }
}

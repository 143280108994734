import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user'
import app from './app'
import org from './org'
import campaigns from './campaigns'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    app,
    org,
    campaigns,
  })

export default rootReducer

const resetStates = () => {
  return async dispatch => {
    dispatch({
      type: 'app/RESET_STATE',
    })
    dispatch({
      type: 'campaigns/RESET_STATE',
    })
    dispatch({
      type: 'organization/RESET_STATE',
    })
    dispatch({
      type: 'user/RESET_STATE',
    })
  }
}

export default resetStates

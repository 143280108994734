import firebase from 'firebase/app'

const getUser = async id => {
  return firebase
    .firestore()
    .collection('users')
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data()
      }
      return null
    })
}

export default getUser

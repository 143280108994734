export const INITIAL_LOAD = 'user/INITIAL_LOAD'
export const RESET_STATE = 'user/RESET_STATE'
export const UPDATE_USER = 'user/UPDATE_USER'
export const SET_CURRENT_ORG = 'user/SET_CURRENT_ORG'

const initialState = {
  currentOrg: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    case INITIAL_LOAD:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_USER: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import styles from './index.module.scss'

import logoImage from 'assets/images/logo.png'

import LinkItem from './LinkItem'
import OrganizationSwitcher from 'components/OrganizationSwitcher'

const Menu = ({ strings, pathname }) => {
  return (
    <div className={styles.wrapper}>
      <img src={logoImage} alt='logo' className={styles.logo} />
      <hr className={styles.line} />
      <h2 className={styles.title}>{strings.campaigns}</h2>
      <ul className={styles.menu}>
        <LinkItem
          itemView='WaitingCampaigns'
          link='/waiting'
          active={pathname === '/waiting'}
          text={strings.waiting}
        />
        <LinkItem
          itemView='ActiveCampaigns'
          link='/'
          active={pathname === '/'}
          text={strings.active}
        />
        <LinkItem
          itemView='FinishedCampaigns'
          link='/finished'
          active={pathname === '/finished'}
          text={strings.finished}
        />
        <LinkItem
          itemView='Videos'
          link='/videos'
          active={pathname === '/videos'}
          text={strings.videos}
        />
        <LinkItem
          itemView='Search'
          link='/search'
          active={pathname === '/search'}
          text={strings.search}
        />
        <LinkItem
          itemView='Archive'
          link='/archive'
          active={pathname === '/archive'}
          text={strings.archive}
        />
        <LinkItem
          itemView='Trash'
          link='/trash'
          active={pathname === '/trash'}
          text={strings.trash}
        />
      </ul>
      <OrganizationSwitcher />
    </div>
  )
}

const mapStateToProps = ({ app, router }) => ({
  strings: app.strings,
  pathname: router.location.pathname,
})

export default connect(mapStateToProps)(Menu)

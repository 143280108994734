const en = {
  email: 'Email',
  password: 'Password',
  login: 'Login',
  reset_password: 'Reset password',
  back_to_login: 'Back to login',
  password_reset_info:
    'If a user with provided email exists, then a password reset email has been sent to provided email.',
  invalid_email: 'Invalid email',
  no_password: 'No password provided',
  campaigns: 'Campaigns',
  active: 'Active',
  waiting: 'Waiting',
  finished: 'Finished',
  active_campaigns: 'Active campaigns',
  waiting_for_approval: 'Waiting for approval',
  finished_campaigns: 'Finished campaigns',
  settings: 'Settings',
  logout: 'Logout',
  something_went_wrong: 'Ooops, something went wrong!',
  reload: 'Reload',
  load_more: 'Load more',
  no_more: 'No more to load',
  organisation: 'Organization',
  organisation_settings: 'Organization Settings',
  user: 'User',
  user_settings: 'User settings',
  update: 'Update',
  facebook_settings: 'Facebook settings',
  delta_settings: 'Delta Settings',
  facebook_default_campaign_settings: 'Default Facebook campaign settings',
  facebook_video_default_campaign_settings: 'Default Facebook Video campaign settings',
  facebook_sold_default_campaign_settings: 'Default Facebook Sold campaign settings',
  instagram_default_campaign_settings: 'Default Instagram campaign settings',
  default_duration: 'Default duration',
  default_start_delay: 'Default start delay',
  default_start_hours_delay: 'Default start hours delay',
  default_budget: 'Default budget',
  default_facebook_duration_info_text:
    'Duration in days, used as default in newly created campaigns',
  default_facebook_start_delay_info_text: 'Days between "object found in feed" to "campaign start"',
  default_facebook_sold_start_delay_info_text: 'Hours between "object found in feed" to "campaign start"',
  default_facebook_budget_info_text:
    'Total spend budget for campaign, will be split into daily budget. Budget/Duration',
  delta_default_campaign_settings: 'Default Delta campaign settings',
  delta_sold_default_campaign_settings: 'Default Delta Sold campaign settings',
  default_delta_start_delay_info_text: 'Days between "object found in feed" to "campaign start"',
  default_delta_budget_info_text:
    'Total spend budget for campaign. Delta auto-optimizes daily spend',
  delta_activity_template_id: 'Delta activity template ID',
  delta_activity_template_id_info_text: 'Activity template used for creation of new campaigns',
  delta_api_client_id: 'Delta API client id',
  delta_api_client_id_info_text:
    'Client ID used for the API requests to Delta, provided to you from Delta',
  save_organization_settings: 'Save organization settings',
  save_user_settings: 'Save user settings',
  default_creative_sender_name: 'Default creative send name',
  invalid_budget: 'Invalid budget',
  invalud_duration: 'Invalid duration',
  invalid_delay: 'Invalid delay',
  invalid_sender: 'Invalid sender',
  invalid_client_id: 'Invalid client ID',
  invalid_template_id: 'Invalid template ID',
  settings_saved: 'Settings saved',
  something_went_wrong_while_saving: 'Something went wrong while saving',
  created_date: 'Created date',
  sort_by: 'Sort by',
  creative: 'Creative',
  create_new_cmp: 'Create new campaign',
  approve: 'Approve',
  hide: 'Hide',
  name: 'Name',
  details_and_preview: 'Details and preview',
  updating_campaigns_from_feed: 'Updating campaigns from feed',
  no_campaigns_here_yet: 'No campaigns here yet',
  campaign_not_found: 'Campaign not found',
  creative_variables: 'Creative variables',
  save: 'Save',
  updated: 'Updated',
  geo_targeting: 'Geo-targeting',
  enable_geo_targeting: 'Enable geo-targeting',
  creatives_preview: 'Creatives preview',
  general_campaign_settings: 'General campaign settings',
  geo_description:
    "Click on the map to create an area for targeting. Move the area by draging the circle. Resize the area by draging the cirle edge. Dont't forget to save your settings!",
  budget: 'Budget',
  budget_description: 'Total budget for the campaign',
  start_date: 'Start date',
  stop_date: 'Stop date',
  next_month: 'Next month',
  previous_month: 'Previous month',
  id: 'ID',
  type: 'Type',
  created: 'Created',
  stop: 'Stop',
  status: 'Status',
  live: 'Live',
  completed: 'Completed',
  new_password: 'New password',
  repeat_new_password: 'Repeat new password',
  password_description: 'Password must be minimum at least 6 characters long',
  relog_text:
    'You have been logged in for too long. Please logout and login again to update this setting.',
  user_settings_saved: 'User settings saved',
  password_to_short: 'Password is too short',
  passwords_do_not_match: 'Passwords do not match',
  language: 'Language',
  statistics: 'Statistics',
  updated_every_10_minutes: 'Updated every 10 minutes',
  impressions: 'Impressions',
  date: 'Date',
  clicks: 'Clicks',
  bids: 'Bids',
  cost: 'Cost',
  spend: 'Spend',
  ctr: 'CTR',
  reach: 'Reach',
  ad_message: 'Ad message',
  ad_title: 'Ad title',
  address: 'Address',
  object_area: 'Object area (kvm)',
  display_date: 'Display date',
  currency: 'Currency',
  main_image: 'Main image',
  object_price: 'Object price',
  object_rooms: 'Object rooms',
  click_tag: 'Landing URL',
  login_via_facebook: 'Login via Facebook',
  no_org_info: 'Your account is not assigned to an organization yet. Please contact us.',
  facebook_auth_settings: 'Facebook Authentication Settings',
  current_ad_account: 'Current Ad Account',
  authentication_expires: 'Authentication expires',
  you_need_to_authenticate_to_facebook:
    'You need to authorize this app to be able to use the facebook ads functionality.',
  use_a_business_manager_account: 'Preferably use a business manager account.',
  authenticate: 'Authenticate',
  reauthenticate: 'Re-Authenticate',
  failed_to_authorize_the_app_for_facebook_use: 'Failed to authorize the app for facebook use',
  none_selected: 'None selected',
  ad_description: 'Ad description',
  ad_name: 'Ad name',
  variant_one: 'Variant One',
  variant_two: 'Variant Two',
  search: 'Search',
  vimeo_videos: 'Vimeo Videos',
  video_needs_approval: 'Video needs to be approved',
  url: 'URL',
  copied: 'Copied',
  video_preview: 'Video preview',
  update_variables_to_generate_a_preview: 'Update variables to generate a preview',
  filter_by_type: 'Filter by type',
  facebook_ab: 'Facebook A/B',
  facebook: 'Facebook',
  facebook_video: 'Facebook Video',
  facebook_sold: 'Facebook Sold',
  delta_display: 'Delta Display',
  delta_display_sold: 'Delta Display Sold',
  instagram: 'Instagram',
  radius: 'Radius',
  delta_geo_requirements_description: 'Delta Campaigns require a radius of minimum 15 km.',
  facebook_geo_requirements_description:
    'Facebook & Instagram campaigns require a radius of minimum 1 km and maximum 60 km.',
  default_geo_radius: 'Default geo targeting radius (km)',
  invalid_radius: 'Invalid radius',
  default_geo_radius_info_text_fb: 'Has to be atleast 1 km and max 60 km',
  default_geo_radius_info_text_delta: 'Has to be atleast 15 km',
  videos: 'Videos',
  no_videos_generated_yet: 'No videos generated yet',
  click_to_copy: 'Click to copy',
  vimeo_video_url: 'Vimeo video url',
  facebook_video_url: 'Facebook video url',
  start_time: 'Start time',
  stop_time: 'Stop time',
  choose_img: 'Choose image',
  choose_images_for_video: 'Choose images for video',
  choose_img_for_campaign: 'Choose image for campaign',
  choose_img_for_video_campaign: 'Choose images for video campaign',
  delete: 'Delete',
  generate_video: 'Generate video',
  create_videos: 'Create videos',
  creative_a_stats: 'Creative A Stats',
  creative_b_stats: 'Creative B Stats',
  creative_a: 'Creative A',
  creative_b: 'Creative B',
  creative_video: 'Creative Video',
  archive: 'Archive',
  trash: 'Trash',
  enterObjectId: 'Enter object ID',
  visning_month: 'Visning month',
  visning_day: 'Visning day',
  visning_time_range: 'Visning time',
}

export default en

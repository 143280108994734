export const INITIAL_LOAD = 'org/INITIAL_LOAD'
export const RESET_STATE = 'org/RESET_STATE'
export const UPDATE_ORG = 'org/UPDATE_ORG'
export const SET_SUBSCRIPTION = 'org/SET_SUBSCRIPTION'

const initialState = {
  subscription: null,
  states: {
    updatingFeed: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialState,
      }
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload,
      }
    }
    case INITIAL_LOAD:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_ORG: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

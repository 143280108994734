import { push } from 'connected-react-router'
import firebase from 'firebase/app'

import resetStates from 'functions/thunk/resetStates'

const logout = () => {
  return async dispatch => {
    dispatch(resetStates())
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(push('/'))
      })
  }
}

export default logout

export const INITIAL_LOAD = 'campaigns/INITIAL_LOAD'
export const UPDATE_CAMPAIGN = 'campaigns/UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_PROP = 'campaigns/UPDATE_CAMPAIGN_PROP'
export const RESET_STATE = 'campaigns/RESET_STATE'
export const LOADING_CAMPAIGNS = 'campaigns/LOADING_CAMPAIGNS'
export const UPDATE_WAITING_CAMPAIGNS = 'campaigns/UPDATE_WAITING_CAMPAIGNS'
export const UPDATE_LIVE_CAMPAIGNS = 'campaigns/UPDATE_LIVE_CAMPAIGNS'
export const UPDATE_CAMPAIGNS = 'campaigns/UPDATE_CAMPAIGNS'
export const UPDATE_SORT_BY = 'campaigns/UPDATE_SORT_BY'
export const UPDATE_SORT_BY_VIDEOS = 'campaigns/UPDATE_SORT_BY_VIDEOS'
export const SET_SUBSCRIPTION = 'campaigns/SET_SUBSCRIPTION'
export const UPDATE_SEARCH_STRING = 'campaigns/UPDATE_SEARCH_STRING'
export const UPDATE_SEARCH_STRING_VIDEOS = 'campaigns/UPDATE_SEARCH_STRING_VIDEOS'
export const UPDATE_SEARCH_SRC_OBJ_ID = 'campaigns/UPDATE_SEARCH_SRC_OBJ_ID'
export const UPDATE_FILTER_BY_TYPE = 'campaigns/UPDATE_FILTER_BY_TYPE'
export const SET_VIDEOS = 'campaigns/SET_VIDEOS'
export const LOADING_ARCHIVES = 'campaigns/LOADING_ARCHIVES'
export const SET_ARCHIVES = 'campaigns/SET_ARCHIVES'
export const SET_ARCHIVES_LENGTH = 'campaigns/SET_ARCHIVES_LENGTH'
export const LOADING_TRASH = 'campaigns/LOADING_TRASH'
export const SET_TRASH = 'campaigns/SET_TRASH'
export const SET_CAMPAIGNS_LENGTH = 'campaigns/SET_CAMPAIGNS_LENGTH'
export const SET_TRASH_LENGTH = 'campaigns/SET_TRASH_LENGTH'
export const SET_SEARCHED_CMPS = 'campaigns/SET_SEARCHED_CMPS'
export const SET_SEARCHED_ARCHIVES = 'campaigns/SET_SEARCHED_ARCHIVES'

const initialState = {
  liveCmps: {},
  waitingCmps: {},
  cmps: {},
  archives: {},
  videos: {},
  trash: {},
  searchedCmps: {},
  searchedArchives: {},
  sortBy: 'created',
  sortByVideos: 'created',
  filterByType: '',
  searchString: '',
  searchStringVideos: '',
  searchSrcObjId: '',
  subscription: null,
  campaignsLength: null,
  archivesLength: null,
  trashLength: null,
  cmpsLoading: false,
  archivesLoading: false,
  trashLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_BY_TYPE:
      return {
        ...state,
        filterByType: action.payload,
      }
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    case INITIAL_LOAD:
      return {
        ...state,
        cmps: action.payload,
      }
    case LOADING_CAMPAIGNS:
      return {
        ...state,
        cmpsLoading: action.payload,
      }
    case LOADING_ARCHIVES:
      return {
        ...state,
        archivesLoading: action.payload,
      }
    case LOADING_TRASH:
        return {
          ...state,
          trashLoading: action.payload,
        }
    case SET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      }

    case UPDATE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      }
    case UPDATE_SEARCH_STRING_VIDEOS:
      return {
        ...state,
        searchStringVideos: action.payload,
      }
    case UPDATE_SEARCH_SRC_OBJ_ID:
      return {
        ...state,
        searchSrcObjId: action.payload,
      }

    case UPDATE_CAMPAIGN:
      return {
        ...state,
        cmps: {
          ...state.cmps,
          [action.id]: action.payload,
        },
      }
    case UPDATE_CAMPAIGN_PROP:
      return {
        ...state,
        cmps: {
          ...state.cmps,
          [action.id]: {
            ...state.cmps[action.id],
            [action.key]: action.value,
          },
        },
      }
    case UPDATE_CAMPAIGNS:
      return {
        ...state,
        cmps: {
          ...state.cmps,
          ...action.payload,
        },
      }
    case UPDATE_LIVE_CAMPAIGNS:
      return {
        ...state,
        liveCmps: {
          ...state.liveCmps,
          ...action.payload,
        },
      }
    case UPDATE_WAITING_CAMPAIGNS:
      return {
        ...state,
        waitingCmps: {
          ...state.waitingCmps,
          ...action.payload,
        },
      }
    case SET_CAMPAIGNS_LENGTH:
        return {
          ...state,
          campaignsLength: action.payload,
        }
    case SET_ARCHIVES:
      return {
        ...state,
        archives: action.payload,
      }
    case SET_ARCHIVES_LENGTH:
        return {
          ...state,
          archivesLength: action.payload,
        }
    case SET_TRASH_LENGTH:
        return {
          ...state,
          trashLength: action.payload,
        }
    case SET_TRASH:
      return {
        ...state,
        trash: action.payload,
      }
    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      }

    case UPDATE_SORT_BY_VIDEOS:
      return {
        ...state,
        sortByVideos: action.payload,
      }
    
    case SET_SEARCHED_CMPS:
      return {
        ...state,
        searchedCmps: action.payload,
      }
  
    case SET_SEARCHED_ARCHIVES:
      return {
        ...state,
        searchedArchives: action.payload,
      }

    default:
      return state
  }
}

const nb = {
  email: 'E-post',
  password: 'Passord',
  login: 'Logg inn',
  reset_password: 'Tilbakestill passord',
  back_to_login: 'Tilbake til innlogging',
  password_reset_info:
    'Hvis en bruker med oppgitt e-postadresse eksisterer, vil en e-post for å sette nytt passord bli sendt til denne e-postadressen.',
  invalid_email: 'Ugyldig e-post',
  no_password: 'Passord ble ikke oppgitt',
  campaigns: 'Kampanjer',
  active: 'Aktiv',
  waiting: 'Venter',
  finished: 'Fullført',
  active_campaigns: 'Aktive kampanjer',
  waiting_for_approval: 'Venter på godkjenning',
  finished_campaigns: 'Fullførte kampanjer',
  settings: 'Innstillinger',
  logout: 'Logg ut',
  something_went_wrong: 'Ooops, noe gikk galt!',
  reload: 'Last inn på nytt', // or 'Oppdater'
  organisation: 'Organisasjon',
  organisation_settings: 'Organisasjonsinnstillinger',
  user: 'Bruker',
  user_settings: 'Brukerinnstillinger',
  update: 'Oppdater',
  facebook_settings: 'Facebook-innstillinger',
  delta_settings: 'Delta-innstillinger',
  facebook_default_campaign_settings: 'Standard Facebook-kampanjeinnstillinger',
  facebook_sold_default_campaign_settings: 'Standard Facebook Sold-kampanjeinnstillinger',
  facebook_video_default_campaign_settings: 'Standard Facebook Video-kampanjeinnstillinger',
  default_duration: 'Standard varighet',
  default_start_delay: 'Standard utsettelse',
  default_start_hours_delay: 'Standard timer utsettelse',
  default_budget: 'Standard budsjett',
  default_facebook_duration_info_text:
    'Varighet i dager. Brukes som standard ved opprettelse av nye kampanjer',
  default_facebook_start_delay_info_text: 'Dager fra annonsen ble oppdaget til kampanjestart',
  default_facebook_sold_start_delay_info_text: 'Timer fra annonsen ble oppdaget til kampanjestart',
  default_facebook_budget_info_text:
    'Totalbudsjett for kampanjen. Fordeles på antall dager kampanjen varer.',
  delta_default_campaign_settings: 'Standard Delta-kampanjeinnstillinger',
  delta_sold_default_campaign_settings: 'Standard DeltaSold-kampanjeinnstillinger',
  default_delta_duration_info_text:
    'Varighet i dager. Brukes som standard ved opprettelse av nye kampanjer',
  default_delta_start_delay_info_text: 'Dager fra annonsen ble oppdaget til kampanjestart',
  default_delta_budget_info_text:
    'Totalbudsjett for kampanjen. Delta optimaliserer daglig budsjett automatisk',
  delta_activity_template_id: 'Delta aktivitetsmal-ID',
  delta_activity_template_id_info_text: 'Aktivitetsmal for nye kampanjer',
  delta_api_client_id: 'Delta API klient id',
  delta_api_client_id_info_text: 'Tildelt klient ID for API-kall til Delta',
  save_organization_settings: 'Lagre organisasjonsinnstillinger',
  save_user_settings: 'Lagre brukerinnstillinger',
  default_creative_sender_name: 'Default creative sender name', // ??? FIXME
  invalid_budget: 'Ugyldig budsjett',
  invalud_duration: 'Ugyldig varighet',
  invalid_delay: 'Ugyldig utsettelse',
  invalid_sender: 'Ugyldig avsender',
  invalid_client_id: 'Ugyldig klient-ID',
  invalid_template_id: 'Ugyldig mal-ID',
  settings_saved: 'Innstillinger lagret',
  something_went_wrong_while_saving: 'Noe gikk galt under lagring',
  created_date: 'Dato opprettet',
  sort_by: 'Sorter etter',
  creative: 'Creative', // ??? FIXME
  approve: 'Godkjenn',
  hide: 'Skjul',
  name: 'Navn',
  details_and_preview: 'Detaljer og forhåndsvisning',
  updating_campaigns_from_feed: 'Oppdaterer kampanjer fra feed',
  no_campaigns_here_yet: 'Ingen kampanjer her enda',
  campaign_not_found: 'Kampanjen ble ikke funnet',
  creative_variables: 'Creative variables', // ??? FIXME
  save: 'Lagre',
  updated: 'Oppdatert',
  geo_targeting: 'Geo-targeting', // ??? FIXME 'Geografisk målretting'?
  enable_geo_targeting: 'Aktiver geo-targeting',
  creatives_preview: 'Creatives preview', // ??? FIXME
  general_campaign_settings: 'Generelle kampanjeinnstillinger',
  geo_description:
    'Klikk på kartet for å lage et område for geografisk målretting. Flytt på området ved å dra på sirkelen. Endre størrelse ved å dra på kanten av sirkelen. Husk å lagre endringene!',
  budget: 'Budsjett',
  budget_description: 'Totalbudsjett for kampanjen',
  start_date: 'Startdato',
  next_month: 'Neste måned',
  previous_month: 'Forrige måned',
  archive: 'Arkiv',
  search:'Søk',
  enterObjectId: 'Skriv inn objekt-ID',
  visning_month: 'Visning maned',
  visning_day: 'Visning dag',
  visning_time_range: 'Visning tid',
}

export default nb

import React, { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
import firebase from 'firebase/app'

import styles from './index.module.scss'

import BasicSelect from 'components/BasicSelect'

import loginAction from 'functions/thunk/loginAction'

const OrganizationSwitcher = ({ user, dispatch }) => {
  const [currentOrg, setCurrentOrg] = useState(null)
  const history = useHistory();
  const [options, setOptions] = useState(null)
  const { orgs } = user
  let localStorage = window.localStorage

  useEffect(() => {
    setCurrentOrg(localStorage.getItem('currentOrg'))
  }, [])

  const changeOrg = orgId => {
    localStorage.setItem('currentOrg', orgId)
    dispatch({type: 'campaigns/RESET_STATE'})
    setCurrentOrg(orgId)
    dispatch(loginAction())
    history.push('/')
  }

  useEffect(() => {
    if(orgs) {
      Promise.all(
        orgs.map(orgId => {
          return firebase
            .firestore()
            .collection('orgs')
            .doc(orgId)
            .get()
            .then(doc => ({
              value: orgId,
              name: doc.data().name,
            }))
        })
      ).then(data => {
        let object = {}
        data.map(org => {
          object[org.value] = org.name
        })
        setOptions(object)
      })
    }
  }, [orgs])

  if (currentOrg && options) {
    return (
      <div className={styles.container}>
        <BasicSelect
          options={options}
          value={currentOrg}
          onChange={val => changeOrg(val)}
          showNoneSeclected={false}
        />
      </div>
    )
  }
  return null
}

export default connect(({ user }) => ({ user }))(OrganizationSwitcher)

import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import classNames from 'classnames'

import styles from './index.module.scss'

import cogImage from 'assets/svg/cog.svg'
import loaderWhite from 'assets/svg/loader-white.svg'

import logoutfn from 'functions/thunk/logout'

import BasicSelect from 'components/BasicSelect'

const Header = ({ pathName, strings, dispatch, name, email, updatingFeed, lang }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'app/CHANGE_LOADING_STATUS',
      payload: true,
    })
    dispatch(logoutfn())
  }

  const toSettings = e => {
    e.preventDefault()
    dispatch(push('/settings'))
  }

  const updateLanguage = value => {
    dispatch({ type: 'app/SET_LANGUAGE', payload: value })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <h1 className={styles.pageTitle}>
          {pathName === '/' && strings.active_campaigns}
          {pathName === '/waiting' && strings.waiting_for_approval}
          {pathName === '/finished' && strings.finished_campaigns}
          {pathName === '/settings' && strings.settings}
          {pathName === '/vimeo-videos' && strings.vimeo_videos}
        </h1>

        <div className={styles.buttonGroup}>
          <div className={styles.orgNameUserContainer}>
            <h2 className={styles.orgName}>{name}</h2>
            <p className={styles.userEmail}>{email}</p>
          </div>
          <button className={styles.settingsButton} onClick={e => toSettings(e)}>
            <img src={cogImage} alt='settings icon' className={styles.settingsIcon} />
            {strings.settings}
          </button>

          <button className={styles.logoutButton} onClick={e => logout(e)}>
            {strings.logout}
          </button>

          <div className={styles.langContainer}>
            <BasicSelect
              options={{
                en: 'English',
                nb: 'Norsk Bokmål',
              }}
              onChange={newValue => updateLanguage(newValue)}
              value={lang}
            />
          </div>
        </div>
      </div>
      <hr className={styles.line} />
      <div className={classNames(styles.statusContainer, updatingFeed && styles.show)}>
        <img src={loaderWhite} />
        <p>{strings.updating_campaigns_from_feed}</p>
      </div>
    </div>
  )
}

const mapStateToProps = ({ router, app, org, user }) => ({
  pathName: router.location.pathname,
  strings: app.strings,
  name: org.name,
  email: user.email,
  updatingFeed: org.states.updatingFeed,
  lang: app.lang,
})

export default connect(mapStateToProps)(Header)

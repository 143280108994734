import React from 'react'

import { Link } from 'react-router-dom'

import classNames from 'classnames'
import styles from './index.module.scss'

import activeCampaignsBlack from 'assets/svg/active-campaigns-black.svg'
import activeCampaignsGray from 'assets/svg/active-campaigns-gray.svg'
import waitingCampaignsBlack from 'assets/svg/waiting-campaigns-black.svg'
import waitingCampaignsGray from 'assets/svg/waiting-campaigns-gray.svg'
import finishedCampaignsBlack from 'assets/svg/finished-campaigns-black.svg'
import finishedCampaignsGray from 'assets/svg/finished-campaigns-gray.svg'
import searchCampaignsBlack from 'assets/svg/search-icon-black.svg'
import searchCampaignsGray from 'assets/svg/search-icon-gray.svg'
import vimeoIcon from 'assets/images/vimeo_icon_dark.png'

const images = {
  ActiveCampaigns: {
    black: activeCampaignsBlack,
    gray: activeCampaignsGray,
  },
  WaitingCampaigns: {
    black: waitingCampaignsBlack,
    gray: waitingCampaignsGray,
  },
  FinishedCampaigns: {
    black: finishedCampaignsBlack,
    gray: finishedCampaignsGray,
  },
  Videos: {
    black: vimeoIcon,
    gray: vimeoIcon,
  },
  Search: {
    black: searchCampaignsBlack,
    gray: searchCampaignsGray,
  },
  Archive: {
    black: finishedCampaignsBlack,
    gray: finishedCampaignsGray,
  },
  Trash: {
    black: finishedCampaignsBlack,
    gray: finishedCampaignsGray,
  },
}

const LinkItem = ({ itemView, link, text, active }) => {
  return (
    <Link to={link} className={styles.linkStyle}>
      <li className={classNames(styles.listItem, active && styles.active)}>
        <div className={classNames(styles.activeBar, active && styles.active)} />
        <div className={styles.iconsContainer}>
          <img
            src={images[itemView].black}
            alt={itemView + ' menu icon'}
            className={classNames(styles.menuIcon, active && styles.active)}
          />
          <img
            src={images[itemView].gray}
            alt={itemView + ' menu icon'}
            className={classNames(styles.menuIcon, !active && styles.active)}
          />
        </div>
        <p className={classNames(styles.menuItemText, active && styles.active)}>{text}</p>
      </li>
    </Link>
  )
}

export default LinkItem
